import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Sign Up" />
        
        <div className="ruzu" style={{backgroundColor:"#f6f9fc"}}>
            <div className="earlyAccessSignUp">
            <div className="earlyAccessSignUp-container">
            <svg className="earlyAccessSignUp-icon" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 707 866.843">
  <defs>
    <linearGradient id="linear-gradient" x1="0.965" y1="0.222" x2="1.323" y2="1.494" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#ffc74e"/>
      <stop offset="1" stop-color="#f137b6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.055" y1="0.124" x2="1.286" y2="1.373" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="-0.104" y1="-0.202" x2="1.239" y2="0.923" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-4" x1="0.5" y1="0" x2="1.238" y2="1.329" href="#linear-gradient"/>
  </defs>
  <g id="Group_923" data-name="Group 923" transform="translate(804 -14871.157)">
    <g id="Group_918" data-name="Group 918" transform="translate(213.945 1560.998)">
      <path id="Path_1449" data-name="Path 1449" d="M-930.006,13405.835c10.742,18.255,54.774,106.3,54.774,106.3s11.43,27.447,33.244,30.783,211.453,25.408,211.453,25.408,33.909,1.374,0-18.4-288.984-152.063-288.984-152.063S-940.748,13387.581-930.006,13405.835Z" transform="translate(51.674 51.621)" fill="url(#linear-gradient)"/>
      <path id="Path_1450" data-name="Path 1450" d="M-741.3,13450.794s-14.749,18.485,16.14,18.4,212.826,0,212.826,0,22.548-.027,5.02-14.209-147.926-139.077-147.926-139.077-8.781-12.923-15.828,0S-741.3,13450.794-741.3,13450.794Z" transform="translate(166.225 -0.003)" fill="url(#linear-gradient-2)"/>
      <path id="Path_1451" data-name="Path 1451" d="M-731.824,13418.023s-13.7.048-9.069,17.127,93.744,321.622,93.744,321.622,5.57,17.3,18.285,4.332,135.46-145.213,135.46-145.213,10.17-6.317-6.285-25.388-137.4-163.435-137.4-163.435-4.071-9.022-24.733-9.046S-731.824,13418.023-731.824,13418.023Z" transform="translate(168.034 65.64)" fill="url(#linear-gradient-3)"/>
      <path id="Path_1452" data-name="Path 1452" d="M-1049.484,13531.416c28.842,26.559,192.507,201.535,192.507,201.535s14.727,16.3,41.6,16.558,258.573,0,258.573,0,16.006,1.932,5.364-34.5-39.459-140.8-39.459-140.8-8.868-20.643-25.643-22.776-427.1-40.872-427.1-40.872S-1078.326,13504.86-1049.484,13531.416Z" transform="translate(43 87.87)" fill="url(#linear-gradient-4)"/>
    </g>
    <text id="ruzu" transform="translate(-794 15660)" fill="#383838" font-size="311" font-family="Montserrat-SemiBold, Montserrat" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">ruzu</tspan></text>
  </g>
</svg>
</div>

                <h1 className="earlyAccessSignUp-title"><span>Automated</span> Reports for Marketing Professionals</h1>

                <p className="earlyAccessSignUp-text">
                    Create and share beautiful, interactive reports using data from your marketing platforms.
                </p>

                <form method="POST" action="https://ruzu.activehosted.com/proc.php" novalidate>
                    <input type="hidden" name="u" value="1" />
                    <input type="hidden" name="f" value="1" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />

                    <ul className="earlyAccessSignUp-form">
                        <li className="emailInput">
                            <input className="TextInput" type="email" name="email" placeholder="Your work email address" required />
                        </li>
                        <li>
                            <button id="_form_1_submit" className="earlyAccessSignUp-button" type="submit">Request FREE Early Access</button>
                        </li>
                    </ul>
                </form>

                <p className="earlyAccessSignUp-disclaimer">
                    Access to Public Beta is by invitation only. Request Access today.
                </p>
            </div>
        </div>
    </Layout>
)

export default IndexPage
